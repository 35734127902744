// frontend/src/components/homepage/HomeSection1/HomeSection1.js
import React, { useEffect, useRef } from 'react';
import VerticalScrollIndicator from '../../general/VerticalScrollIndicator/VerticalScrollIndicator';
import HeadingKeyline from '../../../assets/homepage/headings/HeadingKeyline.svg';
import './HomeSection1.css';
import BrowserIcon1 from '../../../assets/homepage/HomeSection1/browser-icon1.png';
import BrowserIcon2 from '../../../assets/homepage/HomeSection1/browser-icon2.png';
import BrowserIcon3 from '../../../assets/homepage/HomeSection1/browser-icon3.png';
import BrowserIcon4 from '../../../assets/homepage/HomeSection1/browser-icon4.png';
import BrowserIcon5 from '../../../assets/homepage/HomeSection1/browser-icon5.png';
import LoginImage from '../../../assets/homepage/HomeSection1/login-image.png';

const HomeSection1 = () => {
  const headingRef = useRef(null);
  const imageRef = useRef(null);

  useEffect(() => {
    const options = {
      threshold: 0.1, // Trigger when 10% of the element is visible
    };

    const handleIntersection = (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('slide-in-up');
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    if (headingRef.current) observer.observe(headingRef.current);
    if (imageRef.current) observer.observe(imageRef.current);

    return () => {
      if (headingRef.current) observer.unobserve(headingRef.current);
      if (imageRef.current) observer.unobserve(imageRef.current);
    };
  }, []);

  return (
    <div className="home-section-1">
      <div className="content-wrapper">
        <div className="content">
          <div ref={headingRef} className="homeHeadingH2">
            <img src={HeadingKeyline} alt="HeadingKeyline" className="HeadingKeyline" />
            <h2>Go beyond local</h2>
            <p>Manage your site from any browser, hassle-free.</p>
          </div>
          <div className="inner-content-row">
            <div className="login-image-container">
              <img ref={imageRef} src={LoginImage} alt="Login Component" className="login-image" />
              <div className="browser-icons">
                <img src={BrowserIcon1} alt="Browser 1" className="browser-icon" />
                <img src={BrowserIcon2} alt="Browser 2" className="browser-icon" />
                <img src={BrowserIcon3} alt="Browser 3" className="browser-icon" />
                <img src={BrowserIcon4} alt="Browser 4" className="browser-icon" />
                <img src={BrowserIcon5} alt="Browser 5" className="browser-icon" />
              </div>
            </div>
            <p className="side-text">
              Removing local installations and dependencies, simply log in and start building.
            </p>
            <VerticalScrollIndicator />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeSection1;
