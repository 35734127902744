// frontend/src/components/homepage/HomeSection3/HomeSection3.js
import React from 'react';
import './HomeSection3.css';
import HeadingKeyline from '../../../assets/homepage/headings/HeadingKeyline.svg';
import VerticalScrollIndicator from '../../general/VerticalScrollIndicator/VerticalScrollIndicator';
import VideoSource from '../../../assets/homepage/HomeSection3/drag-drop-example.mp4';

const HomeSection3 = () => {
  return (
    <div className="home-section-3">
      <div className="homeHeadingH2">
        <img src={HeadingKeyline} alt="HeadingKeyline" className="HeadingKeyline" />
        <h2>Drag, drop, done</h2>
        <p>Build websites with our visual editor, no code required.</p>
      </div>
      <div className="video-container">
        <VerticalScrollIndicator />
        <video 
          src={VideoSource} 
          autoPlay 
          loop 
          muted 
          playsInline 
          className="demo-video"
        />
      </div>
    </div>
  );
};

export default HomeSection3;
