// frontend/src/contexts/TokensContext.js
import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const TokensContext = createContext();

export const TokensProvider = ({ children }) => {
  const [globalTokens, setGlobalTokens] = useState({});
  const [designTokens, setDesignTokens] = useState({});
  const [editedTokens, setEditedTokens] = useState({});

  useEffect(() => {
    const fetchGlobalTokens = async () => {
      const response = await axios.get('../tokens/globalTokens');
      setGlobalTokens(response.data);
    };
    fetchGlobalTokens();
  }, []);

  const updateDesignTokens = (componentId, newTokens) => {
    setEditedTokens(prevTokens => ({
      ...prevTokens,
      [componentId]: {
        ...(prevTokens[componentId] || {}),
        ...newTokens
      }
    }));
  };

  return (
    <TokensContext.Provider
      value={{
        globalTokens,
        designTokens,
        editedTokens,
        setGlobalTokens,
        setDesignTokens,
        setEditedTokens,
        updateDesignTokens
      }}
    >
      {children}
    </TokensContext.Provider>
  );
};
