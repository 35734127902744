// frontend/src/components/media/MediaContainer.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './MediaContainer.css';

const MediaContainer = ({ selectedFolder }) => {
  const [mediaItems, setMediaItems] = useState([]);

  useEffect(() => {
    if (selectedFolder) {
      const fetchMediaItems = async () => {
        try {
          const token = localStorage.getItem('token');
          const response = await axios.get(`/github/${selectedFolder}/files`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setMediaItems(response.data);
        } catch (error) {
          console.error('Error fetching media items:', error);
        }
      };

      fetchMediaItems();
    }
  }, [selectedFolder]);

  const renderMediaItems = () => {
    return mediaItems.map((item) => {
      const isImage = item.Key.endsWith('.png') || item.Key.endsWith('.jpg') || item.Key.endsWith('.svg');
      const isVideo = item.Key.endsWith('.mp4');
      const mediaUrl = `https://github-repos-storage.s3.eu-north-1.amazonaws.com/${selectedFolder}/${item.Key}`;

      if (isImage) {
        return <img key={item.Key} src={mediaUrl} alt={item.Key} className="mediaItem" />;
      } else if (isVideo) {
        return (
          <video key={item.Key} controls className="mediaItem">
            <source src={mediaUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        );
      }
      return null;
    });
  };

  return (
    <div className="mediaContainer">
      {selectedFolder ? (
        renderMediaItems()
      ) : (
        <p>Please select a folder to view its contents.</p>
      )}
    </div>
  );
};

export default MediaContainer;
