// frontend/src/components/PropertiesSidebar.js
import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { TokensContext } from '../contexts/TokensContext';
import { isColor } from './colorUtils';
import { Dropdown, Menu, Collapse, Input, Row, Col } from 'antd';
import { Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { SideTop, SideRight, SideBottom, SideLeft, TypeLineHeight, TypeFontSize, TypeFontFamily, TypeFontWeight, SizeHeight, SizeWidth, PaddingTop, PaddingRight, PaddingBottom, PaddingLeft } from '../assets/icons/properties-sidebar/icons'; 
import { JustifyContentFlexStart, JustifyContentFlexEnd, AlignItemsFlexStart, AlignItemsFlexEnd, JustifyContentCenter, AlignItemsCenter, MoreFlexOptions, AlignItemsSpaceBetween, JustifyContentSpaceBetween, AlignItemsSpaceAround, JustifyContentSpaceAround, AlignItemsSpaceEvenly, JustifyContentSpaceEvenly, FlexWrapNowrap, FlexWrapWrap, FlexDirectionRowReverse, FlexDirectionRow, FlexDirectionColumn, FlexDirectionColumnReverse } from '../assets/icons/properties-sidebar/icons'; 

import { ReactComponent as ArrowHeadUp } from '../assets/icons/properties-sidebar/arrow-head-up.svg'
import { ReactComponent as ArrowHeadDown } from '../assets/icons/properties-sidebar/arrow-head-down.svg'

import CustomExpandIcon from './properties-sidebar/CustomExpandIcon';
import FlexOptions from './properties-sidebar/FlexOptions';
import { handleFlexOptionChange, renderFlexOptionsDropdown } from './properties-sidebar/FlexOptionsHelper';
import DimensionsSpacingOptions from './properties-sidebar/DimensionsAndSpacing';
import { handleDimensionChange } from './properties-sidebar/DimensionsAndSpacingHelper';
import ColorOptions from './properties-sidebar/ColorOptions';
import { renderColorPicker } from './properties-sidebar/ColorOptionsHelper';
import { getRelevantTokens } from './properties-sidebar/utils';
import StaticContentPanel from './properties-sidebar/StaticContentPanel';

const PropertiesSidebar = ({
    fetchTokens,
    token,
    selectedComponentInfo,
    onTokenChange
}) => {
    const { editedTokens, setEditedTokens, updateDesignTokens, globalTokens } = useContext(TokensContext);

    useEffect(() => {
        console.log('globalTokens available:', globalTokens);
    }, [globalTokens]);

    const [colorValue, setColorValue] = useState(null);
    const [tokenNames, setTokenNames] = useState({});
    const { Panel } = Collapse;

    const handleGlobalTokenSelect = (key, value, propertyType) => {
        if (!selectedComponentInfo || !selectedComponentInfo.name) return;

        const newValue = isColor(value) ? value : key;
        const newTokens = {
            ...editedTokens,
            [selectedComponentInfo.name]: {
                ...editedTokens[selectedComponentInfo.name],
                [propertyType]: value,  // Set the value for the component
            },
        };
        setEditedTokens(newTokens);
        onTokenChange(newTokens); // Ensure the parent is updated with the new tokens
        setTokenNames(prevTokenNames => ({
            ...prevTokenNames,
            [selectedComponentInfo.name]: {
                ...prevTokenNames[selectedComponentInfo.name],
                [propertyType]: key,  // Set the token name for display
            },
        }));
    };

    const renderDropdownMenu = (propertyType, selectedComponentInfo, editedTokens, handleTokenChange, globalTokens, tokenNames, setEditedTokens) => {
        if (!selectedComponentInfo || !selectedComponentInfo.name) return null;

        const relevantTokens = getRelevantTokens(globalTokens, propertyType);

        return (
            <Dropdown
                overlay={
                    <Menu>
                        {Object.entries(relevantTokens).map(([key, value]) => (
                            <Menu.Item key={key} onClick={() => handleGlobalTokenSelect(key, value, propertyType)}>
                                {key} - {value}
                            </Menu.Item>
                        ))}
                    </Menu>
                }
                trigger={['click']}
            >
                <Button style={{ cursor: 'pointer' }}>
                    <CustomExpandIcon />
                </Button>
            </Dropdown>
        );
    };

    const handleTokenChange = (componentId, propertyType, newValue) => {
        if (!componentId) {
            console.error("selectedComponentInfo is null, cannot change token");
            return;
        }
        const newTokens = {
            ...editedTokens,
            [componentId]: {
                ...editedTokens[componentId],
                [propertyType]: newValue,
            },
        };
        setEditedTokens(newTokens);
        onTokenChange(newTokens);
        setTokenNames(prevTokenNames => ({
            ...prevTokenNames,
            [componentId]: {
                ...prevTokenNames[componentId],
                [propertyType]: '',  // Clear the token name if manual value is entered
            },
        }));
    };

    const renderTypographyDropdown = (propertyType) => {
        if (!selectedComponentInfo || !selectedComponentInfo.name) return null;

        const tokenName = tokenNames[selectedComponentInfo.name]?.[propertyType] || '';
        const inputValue = editedTokens[selectedComponentInfo.name]?.[propertyType] || '';

        return (
            <div className="prop-sidebar-item" style={{ display: 'flex', alignItems: 'center' }}>
                <Input
                    placeholder={propertyType}
                    value={tokenName || inputValue}
                    onChange={(e) => handleTokenChange(selectedComponentInfo.name, propertyType, e.target.value)}
                />
                {renderDropdownMenu(propertyType, selectedComponentInfo, editedTokens, handleTokenChange, globalTokens, tokenNames, setEditedTokens)}
            </div>
        );
    };

    const saveChanges = async (tokens) => {
        if (!selectedComponentInfo || !selectedComponentInfo.name) return;

        try {
            const token = localStorage.getItem('token');
            const response = await axios.put(
                'https://www.webbify.io/designTokens',
                {
                    componentName: selectedComponentInfo.name,
                    designTokens: { ...selectedComponentInfo.tokens, ...tokens[selectedComponentInfo.name] || {} },
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            updateDesignTokens(selectedComponentInfo.name, tokens[selectedComponentInfo.name] || {});
            setEditedTokens(prevTokens => ({
                ...prevTokens,
                [selectedComponentInfo.name]: {
                    ...prevTokens[selectedComponentInfo.name],
                    ...tokens[selectedComponentInfo.name]
                }
            }));
            fetchTokens();
            console.log('Tokens updated:', response.data);
        } catch (error) {
            console.error('Error updating tokens:', error.response ? error.response.data : error.message);
        }
    };

    return (
        <div className="properties-sidebar">
            <Collapse defaultActiveKey={['flex-options', 'dimensions-spacing', 'color-options', 'typography', '4']} ghost expandIcon={CustomExpandIcon} >
                {selectedComponentInfo && (
                    <>
                        <Panel header="Flex Options" key="flex-options">
                            <FlexOptions 
                                selectedComponentInfo={selectedComponentInfo}
                                handleTokenChange={handleTokenChange}
                                renderFlexOptionsDropdown={renderDropdownMenu}
                                globalTokens={globalTokens}
                                editedTokens={editedTokens}
                                setEditedTokens={setEditedTokens}
                                tokenNames={tokenNames}
                            />
                        </Panel>
                        {/* Dimensions and spacing Options section */}
                        <Panel header="Dimensions and Spacing" key="dimensions-spacing">
                            <DimensionsSpacingOptions 
                                selectedComponentInfo={selectedComponentInfo}
                                globalTokens={globalTokens}
                                editedTokens={editedTokens}
                                tokenNames={tokenNames}
                                handleTokenChange={handleTokenChange}
                                renderDropdownMenu={renderDropdownMenu}
                                setEditedTokens={setEditedTokens}
                            />
                        </Panel>

                        {/* Color Options section */}
                        <Panel className="colorPanel" header="Color Options" key="color-options">
                            <ColorOptions 
                                selectedComponentInfo={selectedComponentInfo}
                                globalTokens={globalTokens}
                                editedTokens={editedTokens}
                                tokenNames={tokenNames}
                                handleTokenChange={handleTokenChange}
                                renderDropdownMenu={renderDropdownMenu}
                                renderColorPicker={renderColorPicker}
                                setEditedTokens={setEditedTokens}
                            />
                        </Panel>

                        {/* Typography Options section */}
                        <Panel header="Typography" key="typography">
                            <Row gutter={[8, 8]}>
                                <Col span={24}>
                                    <label alt="font-family"><TypeFontFamily /></label>
                                    {renderTypographyDropdown('fontFamily')}
                                </Col>
                            </Row>
                            <Row gutter={[8, 8]}>
                                <Col span={24}>
                                    <label alt="font-weight"><TypeFontWeight /></label>
                                    {renderTypographyDropdown('fontWeight')}
                                </Col>
                            </Row>
                            <Row gutter={[8, 8]}>
                                <Col span={12}>
                                    <label alt="font-size"><TypeFontSize /></label>
                                    {renderTypographyDropdown('fontSize')}
                                </Col>
                                <Col span={12}>
                                    <label alt="line-height"><TypeLineHeight /></label>
                                    {renderTypographyDropdown('lineHeight')}
                                </Col>
                            </Row>
                        </Panel>
                    </>
                )}
                {!selectedComponentInfo && (
                    <StaticContentPanel 
                        handleTokenChange={handleTokenChange}
                        editedTokens={editedTokens}
                        setEditedTokens={setEditedTokens}
                        selectedComponentInfo={selectedComponentInfo}
                        renderFlexOptionsDropdown={renderDropdownMenu}
                        globalTokens={globalTokens}
                        handleDimensionChange={handleTokenChange}
                        renderDropdownMenu={renderDropdownMenu}
                        tokenNames={tokenNames}
                        renderColorPicker={renderColorPicker}
                        renderTypographyDropdown={renderTypographyDropdown}
                    />
                )}
            </Collapse>
            <div className="properties-sidebar-bottom-btn">
                <button onClick={() => { console.log('Save button clicked'); saveChanges(editedTokens); }}>Save</button>
            </div>
        </div>
    );
};

export default PropertiesSidebar;