// frontend/src/hooks/useTokenManagement.js
import { useContext, useEffect } from 'react';
import axios from 'axios';
import { TokensContext } from '../contexts/TokensContext';

const useTokenManagement = (token) => {
  const {
    setDesignTokens,
    setEditedTokens,
    setGlobalTokens,
  } = useContext(TokensContext);

  useEffect(() => {
    const fetchData = async () => {
      if (!token) {
        console.log('No token available.');
        return;
      }

      try {
        const tokensResponse = await axios.get('https://www.webbify.io/designTokens', {
          headers: { Authorization: `Bearer ${token}` },
        });

        setDesignTokens(tokensResponse.data);

        // Fetch and set global tokens similarly, if needed
        // ...

      } catch (error) {
        console.error('Error fetching tokens:', error);
      }
    };

    fetchData();
  }, [token, setDesignTokens, setGlobalTokens]);

  // Additional logic for handling tokens can be added here
};

export default useTokenManagement;
