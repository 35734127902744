// frontend/src/utils/reconstructFrontendStructureFromBackendSchema.js
import { components as componentImports } from '../components/ComponentImports';
import { v4 as uuidv4 } from 'uuid';
import { NestedComponentsContext } from '../contexts/NestedComponentsContext';

export const reconstructFrontendStructureFromBackendSchema = async (content, components, nestedComponentsContext) => {
  console.log('reconstructFrontendStructureFromBackendSchema - Reconstructing Content:', content);
  if (!Array.isArray(content)) {
    console.error("Invalid content structure:", content);
    return [];
  }

  return Promise.all(content.map(async rowData => {
    console.log('reconstructFrontendStructureFromBackendSchema - Row Data:', rowData);
    if (!rowData.columns || !Array.isArray(rowData.columns)) {
      console.error("Invalid rowData structure:", rowData);
      return null;
    }

    const columnData = await Promise.all(rowData.columns.map(async column => {
      const componentData = await Promise.all(column.components.map(async componentInfo => {
        const Component = await componentImports[componentInfo.name]();

        const nestedComponents = componentInfo.nestedComponents 
          ? await reconstructNestedComponents(componentInfo.nestedComponents, nestedComponentsContext)
          : {};

        // Ensure each DivContainer and its nested components have an ID
        const componentId = componentInfo.id || uuidv4();

        return Component ? { 
          id: componentId,
          component: Component.default, 
          name: componentInfo.name, 
          props: componentInfo.props || {},
          tokens: componentInfo.tokens || {},
          nestedComponents
        } : null;
      }));

      return {
        columnSize: column.columnSize,
        components: componentData.filter(comp => comp !== null)
      };
    }));

    return {
      columns: rowData.columns.map(column => column.columnSize),
      components: columnData.map(column => column.components)
    };
  }));
};

async function reconstructNestedComponents(nestedData, nestedComponentsContext) {
  const reconstructedNested = {};

  if (typeof nestedData === 'object' && nestedData !== null) {
    for (const [containerId, columnData] of Object.entries(nestedData)) {
      if (typeof columnData === 'object') {
        for (const [columnId, components] of Object.entries(columnData)) {
          if (Array.isArray(components)) {
            reconstructedNested[containerId] = reconstructedNested[containerId] || {};
            reconstructedNested[containerId][columnId] = await Promise.all(components.map(async componentInfo => {
              const ComponentModule = await componentImports[componentInfo.name]();
              const Component = ComponentModule.default;

              const nestedComponentId = componentInfo.id || uuidv4();

              const newComponent = {
                id: nestedComponentId,
                component: Component,
                name: componentInfo.name,
                props: componentInfo.props || {},
                tokens: componentInfo.tokens || {},
              };

              return newComponent;
            }));
          }
        }
      }
    }
  }

  return reconstructedNested;
}
