// frontend/src/hooks/useDesignTokenManagement.js
import { useContext, useEffect } from 'react';
import axios from 'axios';
import { TokensContext } from '../contexts/TokensContext';

export const useDesignTokenManagement = (token) => {
  const {
    designTokens, 
    setDesignTokens,
    editedTokens,
    setEditedTokens,
    globalTokens,
    setGlobalTokens,
    updateDesignTokens
  } = useContext(TokensContext);

  const fetchTokens = async () => {
    try {
      const designTokenResponse = await axios.get('https://www.webbify.io/designTokens', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setDesignTokens(designTokenResponse.data);

      const globalTokenResponse = await axios.get('https://www.webbify.io/globalTokens', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setGlobalTokens(globalTokenResponse.data);

      setEditedTokens({
        ...designTokenResponse.data,
        global: globalTokenResponse.data.globalTokens,
      });
    } catch (error) {
      console.error('Error fetching tokens:', error);
    }
  };

  const handleTokenChange = (newTokens) => {
    setEditedTokens(newTokens);
  };

  useEffect(() => {
    if (token) {
      fetchTokens();
    }
  }, [token]); 

  return { 
    designTokens,
    editedTokens,
    globalTokens,
    fetchTokens,
    handleTokenChange,
    updateDesignTokens,
    setEditedTokens
  };
};
