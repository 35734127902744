// frontend/src/components/ui_components/DivContainer/DivContainer.js
import React, { useContext, useEffect, useState } from 'react';
import { TokensContext } from '../../../contexts/TokensContext';
import { ComponentPropsContext } from '../../../contexts/ComponentPropsContext';
import { NestedComponentsContext } from '../../../contexts/NestedComponentsContext';
import { v4 as uuidv4 } from 'uuid';
import { components as componentImports } from '../../ComponentImports';
import ComponentAddPanel from './ComponentAddPanel';

const DivContainer = (props) => {
    const { editedTokens } = useContext(TokensContext);
    const tokensFromContext = editedTokens.DivContainer || {};
    const globalTokens = editedTokens.global || {};
    const { selectedComponentProps } = useContext(ComponentPropsContext);
    const componentProps = selectedComponentProps['DivContainer'] || {};
    const { nestedComponents } = useContext(NestedComponentsContext);

    useEffect(() => {
        console.log('DivContainer props updated:', props);
    }, [props]);

    const defaultTokens = {
        height: '100px',
        width: '100%',
        padding: '20px',
        backgroundColor: '#efefef',
        borderRadius: '4px',
    };

    const [mergedTokens, setMergedTokens] = useState({ ...defaultTokens, ...editedTokens.DivContainer });

    useEffect(() => {
        setMergedTokens({
            ...defaultTokens,
            ...tokensFromContext,
        });
    }, [tokensFromContext]);

    const containerStyle = {
        ...mergedTokens,
    };

    const handleComponentClick = () => {
        props.onClick(); // Trigger click handling in the parent component (PageContainer)
    };

    const renderNestedComponents = () => {
        const nestedComponentsForContainer = nestedComponents[props.id] || {};
        return Object.keys(nestedComponentsForContainer).map(columnId => {
            const columnData = nestedComponentsForContainer[columnId];
            if (!columnData || !Array.isArray(columnData)) {
                console.error("Expected columnData to be an array, received:", columnData);
                return null;
            }
            return columnData.map((component, index) => {
                if (!component || typeof component.component !== 'function') {
                    console.warn("Invalid component in columnData:", component);
                    return null;
                }
                const Component = component.component;
                return (
                    <div key={index}>
                        <Component 
                          {...component.props}
                          designTokens={component.tokens}
                          editedTokens={component.tokens}
                        />
                    </div>
                );
            });
        });
    };
    
    return (
        <div style={containerStyle} className="div-container" onClick={handleComponentClick} >
            <ComponentAddPanel divContainerId={props.id} />
            {renderNestedComponents()}
        </div>
    );
};

function getWidthStyle(type, value) {
    switch (type) {
        case 'Hug':
            return 'max-content';
        case 'Fill':
            return '100%';
        case 'Fixed':
            return value;
        default:
            return 'auto';
    }
}

function getHeightStyle(type, value) {
    switch (type) {
        case 'Hug':
            return 'max-content';
        case 'Fill':
            return '100%';
        case 'Fixed':
            return value;
        default:
            return 'auto';
    }
}

export default DivContainer;
