// frontend/src/components/properties-sidebar/DimensionsSpacing.js
import React from 'react';
import { Row, Col } from 'antd';
import { SideTop, SideRight, SideBottom, SideLeft, SizeHeight, SizeWidth, PaddingTop, PaddingRight, PaddingBottom, PaddingLeft } from '../../assets/icons/properties-sidebar/icons';

const DimensionsSpacingOptions = ({
    selectedComponentInfo,
    globalTokens,
    editedTokens,
    tokenNames,
    handleTokenChange,
    renderDropdownMenu,
    setEditedTokens
}) => {
    if (!selectedComponentInfo || !selectedComponentInfo.name) return null;

    const labelInputDropdown = (label, icon, propertyType) => {
        const tokenName = tokenNames[selectedComponentInfo.name]?.[propertyType] || '';
        const inputValue = editedTokens[selectedComponentInfo.name]?.[propertyType] || '';

        return (
            <div className="prop-sidebar-item" style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                <label alt={label}>{icon}</label>
                <input
                    type="text"
                    value={tokenName || inputValue}
                    placeholder={propertyType}
                    onChange={(e) => handleTokenChange(selectedComponentInfo.name, propertyType, e.target.value)}
                />
                {renderDropdownMenu(
                    propertyType,
                    selectedComponentInfo,
                    editedTokens,
                    handleTokenChange,
                    globalTokens,
                    tokenNames,
                    setEditedTokens
                )}
            </div>
        );
    };

    return (
        <>
            <Row gutter={[8, 8]}>
                <Col span={12}>
                    {labelInputDropdown('width', <SizeWidth />, 'width')}
                </Col>
                <Col span={12}>
                    {labelInputDropdown('height', <SizeHeight />, 'height')}
                </Col>
            </Row>
            <label>Margins</label>
            <Row gutter={[8, 8]}>
                <Col span={12}>
                    {labelInputDropdown('margin-top', <SideTop />, 'marginTop')}
                </Col>
                <Col span={12}>
                    {labelInputDropdown('margin-right', <SideRight />, 'marginRight')}
                </Col>
            </Row>
            <Row gutter={[8, 8]}>
                <Col span={12}>
                    {labelInputDropdown('margin-bottom', <SideBottom />, 'marginBottom')}
                </Col>
                <Col span={12}>
                    {labelInputDropdown('margin-left', <SideLeft />, 'marginLeft')}
                </Col>
            </Row>
            <label>Padding</label>
            <Row gutter={[8, 8]}>
                <Col span={12}>
                    {labelInputDropdown('padding-top', <PaddingTop />, 'paddingTop')}
                </Col>
                <Col span={12}>
                    {labelInputDropdown('padding-right', <PaddingRight />, 'paddingRight')}
                </Col>
            </Row>
            <Row gutter={[8, 8]}>
                <Col span={12}>
                    {labelInputDropdown('padding-bottom', <PaddingBottom />, 'paddingBottom')}
                </Col>
                <Col span={12}>
                    {labelInputDropdown('padding-left', <PaddingLeft />, 'paddingLeft')}
                </Col>
            </Row>
        </>
    );
};

export default DimensionsSpacingOptions;
