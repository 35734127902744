//frontend/src/components/properties-sidebar/utils.js
export const getRelevantTokens = (globalTokensData, propertyType) => {
    if (!globalTokensData || Object.keys(globalTokensData).length === 0) {
        return {};
    }

    const firstKey = Object.keys(globalTokensData)[0];
    const globalTokens = globalTokensData[firstKey];

    if (!globalTokens) return {};

    switch (propertyType) {
        case 'color':
        case 'backgroundColor':
            return {
                ...globalTokens.brandColors,
                ...globalTokens.baseColors,
                ...globalTokens.feedbackColors,
                ...globalTokens.backgroundColors,
            };
        case 'fontSize':
        case 'fontWeight':
        case 'lineHeight':
            return globalTokens.typography ? globalTokens.typography[propertyType] : {};
        case 'fontFamily':
            return globalTokens.typography ? globalTokens.typography.fontFamily : {};
        case 'paddingTop':
            return globalTokens.padding ? globalTokens.padding.top : {};
        case 'paddingRight':
            return globalTokens.padding ? globalTokens.padding.right : {};
        case 'paddingBottom':
            return globalTokens.padding ? globalTokens.padding.bottom : {};
        case 'paddingLeft':
            return globalTokens.padding ? globalTokens.padding.left : {};
        case 'marginTop':
            return globalTokens.margin ? globalTokens.margin.top : {};
        case 'marginRight':
            return globalTokens.margin ? globalTokens.margin.right : {};
        case 'marginBottom':
            return globalTokens.margin ? globalTokens.margin.bottom : {};
        case 'marginLeft':
            return globalTokens.margin ? globalTokens.margin.left : {};
        case 'width':
            return globalTokens.width || {};
        case 'height':
            return globalTokens.height || {};
        case 'alignItems':
        case 'justifyContent':
        case 'flexWrap':
        case 'flexDirection':
            return globalTokens.flexbox ? globalTokens.flexbox[propertyType] : {};
        default:
            return {};
    }
};