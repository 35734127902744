// frontend/src/hooks/useSavePageContent.js
import { useContext, useCallback } from 'react';
import axios from 'axios';
import { PageContentContext } from '../contexts/PageContentContext';
import { NestedComponentsContext } from '../contexts/NestedComponentsContext';
import { usePageContentUtils } from './usePageContentUtils';
import { generateUniquePageId } from '../utils/pageUtils';
import PageContainer from '../components/PageContainer';

const useSavePageContent = (
  token, 
  pages, 
  setPages, 
  components, 
  designTokens, 
  selectedComponentInfo, 
  setSelectedComponentInfo, 
  editedTokens, 
  setEditedTokens
) => {
    const { rows } = useContext(PageContentContext);
    const { nestedComponents } = useContext(NestedComponentsContext);
    const { convertFrontendStructureToBackendSchema } = usePageContentUtils(components);

    const savePageContent = useCallback(async (pageIdToSave, pageToSave) => {
      console.log('Attempting to save page:', pageIdToSave);
      if (!token) {
        console.error('No token available.');
        return;
      }

      if (!pageToSave) {
        console.error('Page to save is not defined.');
        return;
      }

      // Convert the front-end structure to the back-end schema format.
      const pageContentForBackend = convertFrontendStructureToBackendSchema(rows, nestedComponents);
      console.log('Converted page content for backend:', pageContentForBackend);

      try {
        const saveResponse = await axios.put(
          'https://www.webbify.io/pageContent',
          {
            userId: 'userId', 
            pageId: pageIdToSave,
            pageName: pageToSave.pageName,
            content: { rows: pageContentForBackend },
            designTokens,
            editedTokens,
            nestedComponents,
            selectedComponentInfo
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log('Save Response:', saveResponse.data);

        // After saving, update the pages state to reflect the saved data.
        const updatedPages = [...pages];
        const pageIndex = updatedPages.findIndex(page => page.pageId === pageIdToSave);
        if (pageIndex !== -1) {
          updatedPages[pageIndex] = {
            ...updatedPages[pageIndex],
            content: (
              <PageContainer 
                components={components}
                designTokens={designTokens}
                rows={rows}
                selectedComponentInfo={selectedComponentInfo} 
                setSelectedComponentInfo={setSelectedComponentInfo}
                editedTokens={editedTokens} 
                setEditedTokens={setEditedTokens}
                nestedComponents={nestedComponents}
              />
            ),
          };
          setPages(updatedPages);
        }
      } catch (error) {
        console.error('Error saving page content:', error);
      }
    }, [token, pages, setPages, components, designTokens, selectedComponentInfo, setSelectedComponentInfo, editedTokens, setEditedTokens, nestedComponents, rows]);

    return savePageContent;
};

export default useSavePageContent;