// frontend/src/pages/MediaPage.js
import React, { useState } from 'react';
import Header from '../components/Header';
import MediaSidebar from '../components/media/MediaSidebar';
import MediaContainer from '../components/media/MediaContainer';
import '../MediaPage.css';

const MediaPage = ({ isLoggedIn, setIsLoggedIn }) => {
  const [selectedFolder, setSelectedFolder] = useState(null);

  const handleFolderSelect = (folderPath) => {
    setSelectedFolder(folderPath);
  };

  return (
    <div className="mediaWrapper">
      <Header className="mini-main-header" isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
      <div className="MediaContents">
        <MediaSidebar onFolderSelect={handleFolderSelect} />
        <MediaContainer selectedFolder={selectedFolder} />
      </div>
    </div>
  );
};

export default MediaPage;
