// frontend/src/contexts/NestedComponentsContext.js
import React, { createContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

export const NestedComponentsContext = createContext();

export const NestedComponentsProvider = ({ children }) => {
    const [nestedComponents, setNestedComponents] = useState({});

    const addNestedComponent = (containerId, columnId, component) => {
        console.log(`Attempting to add a new nested component to container ID: ${containerId}, Column ID: ${columnId}`);
        const newComponentWithId = { 
            ...component,
            id: component.id || uuidv4(),
            props: component.props || {}, 
            tokens: component.tokens || {}
        };
        setNestedComponents(prevState => {
            const updatedState = {
                ...prevState,
                [containerId]: {
                    ...(prevState[containerId] || {}),
                    [columnId]: [...(prevState[containerId]?.[columnId] || []), newComponentWithId],
                }
            };
            console.log(`NestedComponentsContext - After adding, nestedComponents:`, updatedState);
            return updatedState;
            console.log(`State after adding nested component:`, nestedComponents);
        });
    };

    return (
        <NestedComponentsContext.Provider
            value={{
                nestedComponents,
                addNestedComponent
            }}
        >
            {children}
        </NestedComponentsContext.Provider>
    );
};