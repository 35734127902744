// frontend/src/components/properties-sidebar/StaticContentPanel.js
import React from 'react';
import { Row, Col, Button, Input, Collapse, Dropdown, Menu } from 'antd';
import { 
    JustifyContentFlexStart, JustifyContentFlexEnd, AlignItemsFlexStart, AlignItemsFlexEnd, 
    JustifyContentCenter, AlignItemsCenter, FlexWrapNowrap, FlexWrapWrap, 
    FlexDirectionRowReverse, FlexDirectionRow, FlexDirectionColumn, FlexDirectionColumnReverse,
    SizeWidth, SizeHeight, SideTop, SideRight, SideBottom, SideLeft, 
    PaddingTop, PaddingRight, PaddingBottom, PaddingLeft, 
    TypeFontFamily, TypeFontWeight, TypeFontSize, TypeLineHeight 
} from '../../assets/icons/properties-sidebar/icons'; 
import CustomExpandIcon from './CustomExpandIcon';
import { getRelevantTokens } from './utils';

const StaticContentPanel = ({ 
    propertyType,
    renderFlexOptionsDropdown,
    editedTokens,
    globalTokens, 
    renderDropdownMenu,
    tokenNames, 
    renderColorPicker, 
    renderTypographyDropdown 
}) => {
    const { Panel } = Collapse;

    const relevantTokens = getRelevantTokens(globalTokens, propertyType);

    return (
        <Collapse defaultActiveKey={['flex-options', 'dimensions-spacing', 'color-options', 'typography']} expandIcon={({ isActive }) => <CustomExpandIcon isActive={isActive} />}>
        	{/* Flex Options section */}
            <Panel header="Flex Options" key="flex-options">
                <Row gutter={[8, 8]}>
                    <Col span={2}><Button className="flex-button" icon={<JustifyContentFlexStart />} /></Col>
                    <Col span={2}><Button className="flex-button" icon={<JustifyContentFlexEnd />} /></Col>
                    <Col span={2}><Button className="flex-button" icon={<AlignItemsFlexStart />} /></Col>
                    <Col span={2}><Button className="flex-button" icon={<AlignItemsFlexEnd />} /></Col>
                    <Col span={2}><Button className="flex-button" icon={<JustifyContentCenter />} /></Col>
                    <Col span={2}><Button className="flex-button" icon={<AlignItemsCenter />} /></Col>
                    <Col span={2}>{renderFlexOptionsDropdown('justifyContent', globalTokens, {}, {}, {}, tokenNames, () => {})}</Col>
                </Row>
                <Row gutter={[8, 8]}>
                    <Col span={2}><Button className="flex-button" icon={<FlexWrapNowrap />} /></Col>
                    <Col span={2}><Button className="flex-button" icon={<FlexWrapWrap />} /></Col>
                    <Col span={2}><Button className="flex-button" icon={<FlexDirectionRowReverse />} /></Col>
                    <Col span={2}><Button className="flex-button" icon={<FlexDirectionRow />} /></Col>
                    <Col span={2}><Button className="flex-button" icon={<FlexDirectionColumn />} /></Col>
                    <Col span={2}><Button className="flex-button" icon={<FlexDirectionColumnReverse />} /></Col>
                    <Col span={2}></Col>
                </Row>
            </Panel>
            {/* Dimensions and spacing Options section */}
            <Panel header="Dimensions and Spacing" key="dimensions-spacing">
                <Row gutter={[8, 8]}>
                    <Col span={12}>
                    	<div className="prop-sidebar-item" style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
	                        <label alt="width"><SizeWidth /></label>
	                        <Input type="text" placeholder="width" value="" />
                            <Dropdown
                                overlay={
                                    <Menu>
                                        {Object.entries(relevantTokens).map(([key, value]) => (
                                            <Menu.Item key={key} onClick={() => handleGlobalTokenSelect(key, value, propertyType)}>
                                                {key} - {value}
                                            </Menu.Item>
                                        ))}
                                    </Menu>
                                }
                                trigger={['click']}
                            >
                                <Button style={{ cursor: 'pointer' }}>
                                    <CustomExpandIcon />
                                </Button>
                            </Dropdown>
	                    </div>
                    </Col>
                    <Col span={12}>
                    	<div className="prop-sidebar-item" style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
	                        <label alt="height"><SizeHeight /></label>
	                        <Input type="text" placeholder="height" value="" />
                            <Dropdown
                                overlay={
                                    <Menu>
                                        {Object.entries(relevantTokens).map(([key, value]) => (
                                            <Menu.Item key={key} onClick={() => handleGlobalTokenSelect(key, value, propertyType)}>
                                                {key} - {value}
                                            </Menu.Item>
                                        ))}
                                    </Menu>
                                }
                                trigger={['click']}
                            >
                                <Button style={{ cursor: 'pointer' }}>
                                    <CustomExpandIcon />
                                </Button>
                            </Dropdown>
                        </div>
                    </Col>
                </Row>
                <label>Margins</label>
                <Row gutter={[8, 8]}>
                    <Col span={12}>
                    	<div className="prop-sidebar-item" style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
	                    	<label alt="margin-top"><SideTop /></label>
	                    	<Input type="text" placeholder="margin-top" value="" />
	                    	<Dropdown
                                overlay={
                                    <Menu>
                                        {Object.entries(relevantTokens).map(([key, value]) => (
                                            <Menu.Item key={key} onClick={() => handleGlobalTokenSelect(key, value, propertyType)}>
                                                {key} - {value}
                                            </Menu.Item>
                                        ))}
                                    </Menu>
                                }
                                trigger={['click']}
                            >
                                <Button style={{ cursor: 'pointer' }}>
                                    <CustomExpandIcon />
                                </Button>
                            </Dropdown>
	                    </div>
                    </Col>
                    <Col span={12}>
                    	<div className="prop-sidebar-item" style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
	                    	<label alt="margin-right"><SideRight /></label>
	                    	<Input type="text" placeholder="margin-right" value="" />
	                    	<Dropdown
                                overlay={
                                    <Menu>
                                        {Object.entries(relevantTokens).map(([key, value]) => (
                                            <Menu.Item key={key} onClick={() => handleGlobalTokenSelect(key, value, propertyType)}>
                                                {key} - {value}
                                            </Menu.Item>
                                        ))}
                                    </Menu>
                                }
                                trigger={['click']}
                            >
                                <Button style={{ cursor: 'pointer' }}>
                                    <CustomExpandIcon />
                                </Button>
                            </Dropdown>
	                    </div>
	                </Col>
                </Row>
                <Row gutter={[8, 8]}>
                    <Col span={12}>
                    	<div className="prop-sidebar-item" style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
	                    	<label alt="margin-bottom"><SideBottom /></label>
	                    	<Input type="text" placeholder="margin-bottom" value="" />
	                    	<Dropdown
                                overlay={
                                    <Menu>
                                        {Object.entries(relevantTokens).map(([key, value]) => (
                                            <Menu.Item key={key} onClick={() => handleGlobalTokenSelect(key, value, propertyType)}>
                                                {key} - {value}
                                            </Menu.Item>
                                        ))}
                                    </Menu>
                                }
                                trigger={['click']}
                            >
                                <Button style={{ cursor: 'pointer' }}>
                                    <CustomExpandIcon />
                                </Button>
                            </Dropdown>
	                    	</div>
                    </Col>
                    <Col span={12}>
                    	<div className="prop-sidebar-item" style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                    		<label alt="margin-left"><SideLeft /></label>
                    		<input type="text" placeholder="margin-left" value="" />
                    		<Dropdown
                                overlay={
                                    <Menu>
                                        {Object.entries(relevantTokens).map(([key, value]) => (
                                            <Menu.Item key={key} onClick={() => handleGlobalTokenSelect(key, value, propertyType)}>
                                                {key} - {value}
                                            </Menu.Item>
                                        ))}
                                    </Menu>
                                }
                                trigger={['click']}
                            >
                                <Button style={{ cursor: 'pointer' }}>
                                    <CustomExpandIcon />
                                </Button>
                            </Dropdown>
                		</div>
                    </Col>
                </Row>
                <label>Padding</label>
                <Row gutter={[8, 8]}>
                    <Col span={12}>
                    	<div className="prop-sidebar-item" style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                    		<label alt="padding-top"><PaddingTop /></label>
                    		<input type="text" placeholder="padding-top" value="" />
                    		<Dropdown
                                overlay={
                                    <Menu>
                                        {Object.entries(relevantTokens).map(([key, value]) => (
                                            <Menu.Item key={key} onClick={() => handleGlobalTokenSelect(key, value, propertyType)}>
                                                {key} - {value}
                                            </Menu.Item>
                                        ))}
                                    </Menu>
                                }
                                trigger={['click']}
                            >
                                <Button style={{ cursor: 'pointer' }}>
                                    <CustomExpandIcon />
                                </Button>
                            </Dropdown>
                		</div>
                    </Col>
                    <Col span={12}>
                    	<div className="prop-sidebar-item" style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                    		<label alt="padding-right"><PaddingRight /></label>
                    		<input type="text" placeholder="padding-right" value="" />
                    		<Dropdown
                                overlay={
                                    <Menu>
                                        {Object.entries(relevantTokens).map(([key, value]) => (
                                            <Menu.Item key={key} onClick={() => handleGlobalTokenSelect(key, value, propertyType)}>
                                                {key} - {value}
                                            </Menu.Item>
                                        ))}
                                    </Menu>
                                }
                                trigger={['click']}
                            >
                                <Button style={{ cursor: 'pointer' }}>
                                    <CustomExpandIcon />
                                </Button>
                            </Dropdown>
                		</div>
                    </Col>
                </Row>
                <Row gutter={[8, 8]}>
                    <Col span={12}>
                    	<div className="prop-sidebar-item" style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                    		<label alt="padding-bottom"><PaddingBottom /></label>
                    		<input type="text" placeholder="padding-bottom" value="" />
                    		<Dropdown
                                overlay={
                                    <Menu>
                                        {Object.entries(relevantTokens).map(([key, value]) => (
                                            <Menu.Item key={key} onClick={() => handleGlobalTokenSelect(key, value, propertyType)}>
                                                {key} - {value}
                                            </Menu.Item>
                                        ))}
                                    </Menu>
                                }
                                trigger={['click']}
                            >
                                <Button style={{ cursor: 'pointer' }}>
                                    <CustomExpandIcon />
                                </Button>
                            </Dropdown>
                		</div>
                    </Col>
                    <Col span={12}>
                    	<div className="prop-sidebar-item" style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                    		<label alt="padding-left"><PaddingLeft /></label>
                    		<input type="text" placeholder="padding-left" value="" />
                    		<Dropdown
                                overlay={
                                    <Menu>
                                        {Object.entries(relevantTokens).map(([key, value]) => (
                                            <Menu.Item key={key} onClick={() => handleGlobalTokenSelect(key, value, propertyType)}>
                                                {key} - {value}
                                            </Menu.Item>
                                        ))}
                                    </Menu>
                                }
                                trigger={['click']}
                            >
                                <Button style={{ cursor: 'pointer' }}>
                                    <CustomExpandIcon />
                                </Button>
                            </Dropdown>
                		</div>
                    </Col>
                </Row>
            </Panel>
            {/* Color Options section */}
            <Panel className="colorPanel" header="Color Options" key="color-options">
                <Row gutter={[8, 8]}>
                    <Col span={12}>
                    	<div className="prop-sidebar-item" style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                        	<input className="color-picker" type="color" value="" />
				            <input type="text" value="" placeholder="background-color" />
                            <Dropdown
                                overlay={
                                    <Menu>
                                        {Object.entries(relevantTokens).map(([key, value]) => (
                                            <Menu.Item key={key} onClick={() => handleGlobalTokenSelect(key, value, propertyType)}>
                                                {key} - {value}
                                            </Menu.Item>
                                        ))}
                                    </Menu>
                                }
                                trigger={['click']}
                            >
                                <Button style={{ cursor: 'pointer' }}>
                                    <CustomExpandIcon />
                                </Button>
                            </Dropdown>
			            </div>
                    </Col>
                </Row>
                <Row gutter={[8, 8]}>
                    <Col span={12}>
                        <div className="prop-sidebar-item" style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                        	<input className="color-picker" type="color" value="" />
				            <input type="text" value="" placeholder="color" />
                            <Dropdown
                                overlay={
                                    <Menu>
                                        {Object.entries(relevantTokens).map(([key, value]) => (
                                            <Menu.Item key={key} onClick={() => handleGlobalTokenSelect(key, value, propertyType)}>
                                                {key} - {value}
                                            </Menu.Item>
                                        ))}
                                    </Menu>
                                }
                                trigger={['click']}
                            >
                                <Button style={{ cursor: 'pointer' }}>
                                    <CustomExpandIcon />
                                </Button>
                            </Dropdown>
			            </div>
                    </Col>
                </Row>
            </Panel>
            {/* Typography Options section */}
            <Panel header="Typography" key="typography">
                <Row gutter={[8, 8]}>
                    <Col span={12}>
                    	<div className="prop-sidebar-item" style={{ display: 'flex', alignItems: 'center' }}>
                        	<label alt="font-family"><TypeFontFamily /></label>
                        	<Input type="text" placeholder="font-family" value="" />
                            <Dropdown
                                overlay={
                                    <Menu>
                                        {Object.entries(relevantTokens).map(([key, value]) => (
                                            <Menu.Item key={key} onClick={() => handleGlobalTokenSelect(key, value, propertyType)}>
                                                {key} - {value}
                                            </Menu.Item>
                                        ))}
                                    </Menu>
                                }
                                trigger={['click']}
                            >
                                <Button style={{ cursor: 'pointer' }}>
                                    <CustomExpandIcon />
                                </Button>
                            </Dropdown>
                    	</div>
                    </Col>
                </Row>
                <Row gutter={[8, 8]}>
                    <Col span={12}>
                    	<div className="prop-sidebar-item" style={{ display: 'flex', alignItems: 'center' }}>
                        	<label alt="font-weight"><TypeFontWeight /></label>
                        	<Input type="text" placeholder="font-weight" value="" />
                            <Dropdown
                                overlay={
                                    <Menu>
                                        {Object.entries(relevantTokens).map(([key, value]) => (
                                            <Menu.Item key={key} onClick={() => handleGlobalTokenSelect(key, value, propertyType)}>
                                                {key} - {value}
                                            </Menu.Item>
                                        ))}
                                    </Menu>
                                }
                                trigger={['click']}
                            >
                                <Button style={{ cursor: 'pointer' }}>
                                    <CustomExpandIcon />
                                </Button>
                            </Dropdown>
                    	</div>
                    </Col>
                </Row>
                <Row gutter={[8, 8]}>
                    <Col span={12}>
                    	<div className="prop-sidebar-item" style={{ display: 'flex', alignItems: 'center' }}>
                        	<label alt="font-size"><TypeFontSize /></label>
                        	<Input type="text" placeholder="font-size" value="" />
                            <Dropdown
                                overlay={
                                    <Menu>
                                        {Object.entries(relevantTokens).map(([key, value]) => (
                                            <Menu.Item key={key} onClick={() => handleGlobalTokenSelect(key, value, propertyType)}>
                                                {key} - {value}
                                            </Menu.Item>
                                        ))}
                                    </Menu>
                                }
                                trigger={['click']}
                            >
                                <Button style={{ cursor: 'pointer' }}>
                                    <CustomExpandIcon />
                                </Button>
                            </Dropdown>
                    	</div>
                    </Col>
                    <Col span={12}>
                    	<div className="prop-sidebar-item" style={{ display: 'flex', alignItems: 'center' }}>
                        	<label alt="line-height"><TypeLineHeight /></label>
                        	<Input type="text" placeholder="line-height" value="" />
                            <Dropdown
                                overlay={
                                    <Menu>
                                        {Object.entries(relevantTokens).map(([key, value]) => (
                                            <Menu.Item key={key} onClick={() => handleGlobalTokenSelect(key, value, propertyType)}>
                                                {key} - {value}
                                            </Menu.Item>
                                        ))}
                                    </Menu>
                                }
                                trigger={['click']}
                            >
                                <Button style={{ cursor: 'pointer' }}>
                                    <CustomExpandIcon />
                                </Button>
                            </Dropdown>
                    	</div>
                    </Col>
                </Row>
            </Panel>
        </Collapse>
    );
};

export default StaticContentPanel;
