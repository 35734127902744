// frontend/src/contexts/PageContentContext.js
import React, { createContext, useState, useCallback } from 'react';

export const PageContentContext = createContext();

export const PageContentProvider = ({ children }) => {
  const [pageContent, setPageContent] = useState([]);
  const [rows, setRows] = useState([]);

  const handleSetPageContent = useCallback((newContent) => {
      console.log('PageContentContext - Received Page Content:', newContent);
      setPageContent(newContent);
      console.log('PageContentContext - Updated Page Content:', pageContent);
  }, []);

  const handleSetRows = useCallback((newRows) => {
      console.log('PageContentContext - Received Rows:', newRows);
      setRows(newRows);
      console.log('PageContentContext - Updated Rows:', rows);
  }, []);

  return (
    <PageContentContext.Provider value={{ pageContent, setPageContent: handleSetPageContent, rows, setRows: handleSetRows }}>
      {children}
    </PageContentContext.Provider>
  );
};