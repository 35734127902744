// frontend/src/hooks/useComponentDrop.js
import { useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { PageContentContext } from '../contexts/PageContentContext';
import { NestedComponentsContext } from '../contexts/NestedComponentsContext';
import { getComponentProps } from './getComponentProps';
import { components as componentImports } from '../components/ComponentImports';

const useComponentDrop = (components) => {
    const { rows, setRows } = useContext(PageContentContext);
    const { addNestedComponent } = useContext(NestedComponentsContext);

    const handleComponentDrop = async (event, rowIndex, colIndex, divContainerId = null) => {
        event.preventDefault();
        const componentName = event.dataTransfer.getData('componentName');
        console.log(`Dropped component name: ${componentName}`);

        const designTokensString = event.dataTransfer.getData('designTokens');
        const componentDesignTokens = designTokensString ? JSON.parse(designTokensString) : {};

        if (divContainerId) {
            console.log(`Dropping inside DivContainer ID: ${divContainerId}`);
            const ComponentModule = await components[componentName]();
            if (!ComponentModule) {
                console.error(`Component module not found for: ${componentName}`);
                return;
            }
            const Component = ComponentModule.default;

            const newComponent = {
                id: uuidv4(),
                component: Component,
                name: componentName,
                tokens: componentDesignTokens,
                props: getComponentProps(Component),
            };

            addNestedComponent(divContainerId, colIndex, newComponent);
        } else {
            console.log(`Dropping on the page, not in a specific DivContainer`);
            const sourceRow = event.dataTransfer.getData('sourceRow');
            const sourceCol = event.dataTransfer.getData('sourceCol');

            if (sourceRow && sourceCol) {
                // Existing component is being moved
                const srcRowIndex = parseInt(sourceRow, 10);
                const srcColIndex = parseInt(sourceCol, 10);
                console.log(`Moving existing component from row ${srcRowIndex}, col ${srcColIndex}`);
                const componentToMove = rows[srcRowIndex].components[srcColIndex].splice(0, 1)[0];
                
                // Check if the target column exists and is an array
                if (!Array.isArray(rows[rowIndex].components[colIndex])) {
                    rows[rowIndex].components[colIndex] = [];
                }

                rows[rowIndex].components[colIndex].push(componentToMove);
            } else {
                // New component is being added
                console.log(`Adding new component: ${componentName}`);
                const ComponentModule = await components[componentName]();
                if (!ComponentModule) {
                    console.error(`Component module not found for: ${componentName}`);
                    return;
                }
                const Component = ComponentModule.default;

                const newComponent = {
                    id: uuidv4(),
                    component: Component,
                    name: componentName,
                    tokens: componentDesignTokens,
                    props: getComponentProps(Component),
                };

                if (!Array.isArray(rows[rowIndex].components[colIndex])) {
                    rows[rowIndex].components[colIndex] = [];
                }
                rows[rowIndex].components[colIndex].push(newComponent);
            }
            setRows([...rows]);
        }
    };


    return handleComponentDrop;
};

export default useComponentDrop;