// frontend/src/components/properties-sidebar/FlexOptions.js
import React from 'react';
import { Row, Col, Button, Dropdown, Menu } from 'antd';
import { JustifyContentFlexStart, JustifyContentFlexEnd, AlignItemsFlexStart, AlignItemsFlexEnd, JustifyContentCenter, AlignItemsCenter, MoreFlexOptions, FlexWrapNowrap, FlexWrapWrap, FlexDirectionRowReverse, FlexDirectionRow, FlexDirectionColumn, FlexDirectionColumnReverse } from '../../assets/icons/properties-sidebar/icons';

const FlexOptions = ({ handleTokenChange, renderFlexOptionsDropdown, globalTokens, editedTokens, setEditedTokens, selectedComponentInfo, tokenNames }) => (
    <>
        <Row gutter={[8, 8]}>
            <Col span={2}><Button className="flex-button" icon={<JustifyContentFlexStart />} onClick={() => handleTokenChange(selectedComponentInfo.name, 'justifyContent', 'flex-start')} /></Col>
            <Col span={2}><Button className="flex-button" icon={<JustifyContentFlexEnd />} onClick={() => handleTokenChange(selectedComponentInfo.name, 'justifyContent', 'flex-end')} /></Col>
            <Col span={2}><Button className="flex-button" icon={<AlignItemsFlexStart />} onClick={() => handleTokenChange(selectedComponentInfo.name, 'alignItems', 'flex-start')} /></Col>
            <Col span={2}><Button className="flex-button" icon={<AlignItemsFlexEnd />} onClick={() => handleTokenChange(selectedComponentInfo.name, 'alignItems', 'flex-end')} /></Col>
            <Col span={2}><Button className="flex-button" icon={<JustifyContentCenter />} onClick={() => handleTokenChange(selectedComponentInfo.name, 'justifyContent', 'center')} /></Col>
            <Col span={2}><Button className="flex-button" icon={<AlignItemsCenter />} onClick={() => handleTokenChange(selectedComponentInfo.name, 'alignItems', 'center')} /></Col>
            <Col span={2}>{renderFlexOptionsDropdown('justifyContent', globalTokens, selectedComponentInfo, editedTokens, setEditedTokens, tokenNames, handleTokenChange)}</Col>
        </Row>
        <Row gutter={[8, 8]}>
            <Col span={2}><Button className="flex-button" icon={<FlexWrapNowrap />} onClick={() => handleTokenChange(selectedComponentInfo.name, 'flexWrap', 'nowrap')} /></Col>
            <Col span={2}><Button className="flex-button" icon={<FlexWrapWrap />} onClick={() => handleTokenChange(selectedComponentInfo.name, 'flexWrap', 'wrap')} /></Col>
            <Col span={2}><Button className="flex-button" icon={<FlexDirectionRowReverse />} onClick={() => handleTokenChange(selectedComponentInfo.name, 'flexDirection', 'row-reverse')} /></Col>
            <Col span={2}><Button className="flex-button" icon={<FlexDirectionRow />} onClick={() => handleTokenChange(selectedComponentInfo.name, 'flexDirection', 'row')} /></Col>
            <Col span={2}><Button className="flex-button" icon={<FlexDirectionColumn />} onClick={() => handleTokenChange(selectedComponentInfo.name, 'flexDirection', 'column')} /></Col>
            <Col span={2}><Button className="flex-button" icon={<FlexDirectionColumnReverse />} onClick={() => handleTokenChange(selectedComponentInfo.name, 'flexDirection', 'column-reverse')} /></Col>
            <Col span={2}></Col>
        </Row>
    </>
);

export default FlexOptions;
