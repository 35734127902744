import React, { useState, useEffect } from 'react';
import { AppstoreOutlined, FolderOutlined, SaveOutlined } from '@ant-design/icons'; // Import the icons you need
import GlobalTokenEditor from './GlobalTokenEditor';
import { Select } from 'antd';
import axios from 'axios';

const { Option } = Select;

const UiBuilderControls = ({ onSave, onSavePageContainer, onRepoSelect }) => {
  const [isTokenEditorVisible, setIsTokenEditorVisible] = useState(false);
  const [repos, setRepos] = useState([]);

  useEffect(() => {
    const fetchClonedRepos = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('/github/cloned-repos', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (Array.isArray(response.data)) {
          setRepos(response.data);
        } else {
          console.error('Unexpected response format:', response.data);
          setRepos([]);
        }
      } catch (error) {
        console.error('Failed to fetch repositories:', error);
        setRepos([]);
      }
    };

    fetchClonedRepos();
  }, []);

  const handleOpenTokenEditor = () => {
    console.log('Opening Global Token Editor');
    setIsTokenEditorVisible(true);
  };

  const handleCloseTokenEditor = () => {
    console.log('Closing Global Token Editor');
    setIsTokenEditorVisible(false);
  };

  const handleRepoChange = (value) => {
    console.log('Selected Repo:', value);
    onRepoSelect(value); // Ensure the function is called correctly
  };
  
  return (
    <div className="uiBuilderTopControls">
      <div className="sidebar-controls">
        <button onClick={handleOpenTokenEditor}>Tokens</button>
        <GlobalTokenEditor
          isVisible={isTokenEditorVisible}
          onClose={handleCloseTokenEditor}
        />
        <Select defaultValue="pages" onChange={handleRepoChange}>
          <Option value="pages">Local Pages</Option>
          {repos.map((repo) => (
            <Option key={repo} value={repo}>
              {repo}
            </Option>
          ))}
        </Select>
        <button onClick={onSave}><SaveOutlined />DB Save</button>
        <button onClick={onSavePageContainer}><SaveOutlined />Cloud Save</button>
      </div>
    </div>
  );
};

export default UiBuilderControls;
