// frontend/src/components/homepage/HomeSection5/HomeSection5.js
import React, { useState, useRef, useEffect } from 'react';
import './HomeSection5.css';
import HeadingKeyline from '../../../assets/homepage/headings/HeadingKeyline.svg';
import Image1 from '../../../assets/homepage/HomeSection5/AIimage1.png';
import Image2 from '../../../assets/homepage/HomeSection5/AIimage2.png';
import Image3 from '../../../assets/homepage/HomeSection5/AIimage3.png';

const HomeSection5 = () => {
  const [activeDiv, setActiveDiv] = useState(0);
  const containerRef = useRef(null);
  const divRefs = [useRef(null), useRef(null), useRef(null)];
  const isScrolling = useRef(false);

  const handleScroll = (event) => {
    if (isScrolling.current) return; // Prevents multiple triggers

    const delta = Math.sign(event.deltaY);
    const nextDiv = activeDiv + delta;

    if (nextDiv >= 0 && nextDiv < divRefs.length) {
      event.preventDefault();
      setActiveDiv(nextDiv);
      divRefs[nextDiv].current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    // Allow scrolling up and down beyond the HomeSection5
    if (nextDiv < 0 || nextDiv >= divRefs.length) {
      window.removeEventListener('wheel', handleScroll);
    }

    // Set a timeout to prevent multiple fast scroll events
    isScrolling.current = true;
    setTimeout(() => {
      isScrolling.current = false;
    }, 800);
  };

  const handleKeylineClick = (index) => {
    setActiveDiv(index);
    // No scrollIntoView to prevent scrolling on click
  };

  const handleContentDivClick = (index) => {
    setActiveDiv(index);
    // No scrollIntoView to prevent scrolling on click
  };

  useEffect(() => {
    window.addEventListener('wheel', handleScroll, { passive: false });

    return () => {
      window.removeEventListener('wheel', handleScroll);
    };
  }, [activeDiv]);

  return (
    <div className="home-section-5" ref={containerRef}>
      <div className="section-content">
        <div className="homeHeadingH2">
          <img src={HeadingKeyline} alt="HeadingKeyline" className="HeadingKeyline" />
          <h2>Smart development with AI</h2>
          <p>Let AI handle basic tasks, documentation and more. Focus on what matters.</p>
        </div>
        <div className="content-wrapper">
          <div className="left-content">
            <img src={activeDiv === 0 ? Image1 : activeDiv === 1 ? Image2 : Image3} alt="Relevant AI Feature" />
            <div className="keylines">
              {divRefs.map((_, index) => (
                <div
                  key={index}
                  className={`keyline ${activeDiv === index ? 'active' : ''}`}
                  onClick={() => handleKeylineClick(index)}
                />
              ))}
            </div>
          </div>

          <div className="right-content">
            <div 
              className={`content-div ${activeDiv === 0 ? 'active' : ''}`} 
              ref={divRefs[0]}
              onClick={() => handleContentDivClick(0)}
            >
              <div className="rightContentCard">
                <h4>Create a new component</h4>
                <p>Create a component based on a existing component template.</p>
              </div>
              <p>Prompt Ai to reference files to create consistent new component code ready to use.</p>
            </div>
            <div 
              className={`content-div ${activeDiv === 1 ? 'active' : ''}`} 
              ref={divRefs[1]}
              onClick={() => handleContentDivClick(1)}
            >
              <div className="rightContentCard">
                <h4>Convert into react</h4>
                <p>Convert code into react inline with the component structure.</p>
              </div>
              <p>Utilise AI to convert your html code into react components with a press of a button</p>
            </div>
            <div 
              className={`content-div ${activeDiv === 2 ? 'active' : ''}`} 
              ref={divRefs[2]}
              onClick={() => handleContentDivClick(2)}
            >
              <div className="rightContentCard">
                <h4>Check code for errors and fix</h4>
                <p>Checks and debug code against similar component files.</p>
              </div>
              <p>Prompt Ai to check an open file for any errors and compare the changes.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeSection5;
