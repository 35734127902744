import React, { useContext, useState, useEffect } from 'react';
import { Modal } from 'antd';  // Assuming you are using Ant Design
import axios from 'axios';  // Import axios for making the HTTP request
import { TokensContext } from '../contexts/TokensContext';
import { isColor, convertColorToHex } from './colorUtils'; 

const GlobalTokenEditor = ({ isVisible, onClose }) => {
  // Consolidate the useContext calls into one
  const { editedTokens, setEditedTokens, globalTokens, updateDesignTokens } = useContext(TokensContext);
  const [localTokens, setLocalTokens] = useState(globalTokens);
  const [originalTokens, setOriginalTokens] = useState(globalTokens);
  const tokensId = Object.keys(localTokens)[0];
  const actualTokens = localTokens[tokensId];

  //console.log('Global Tokens from context:', globalTokens);

  const handleTokenChange = (category, key, newValue) => {
    const adjustedValue = typeof newValue === 'string' && isColor(newValue) ? convertColorToHex(newValue) : newValue;
    const updatedTokens = {
      ...localTokens,
      [tokensId]: {
        ...localTokens[tokensId],
        [category]: {
          ...localTokens[tokensId][category],
          [key]: adjustedValue,
        },
      },
    };
    setLocalTokens(updatedTokens);
    //console.log('Updated Tokens:', updatedTokens);  // Log the updated tokens to the console
  };

  const handleCancel = () => {
    setLocalTokens(originalTokens);
    onClose();
  };

  const handleSave = async () => {
    console.log('handleSave called');
    try {
      const token = localStorage.getItem('token');
      console.log('Token:', token);  // Log the retrieved token

      if (!token) {
        console.log('No token available.');
        return;
      }

      const url = 'https://www.webbify.io/globalTokens';
      const data = { globalTokens: localTokens[tokensId] };  // Ensure only the globalTokens part is sent
      const headers = { Authorization: `Bearer ${token}` };
      
      //console.log('Sending request:', { url, data, headers });  // Log the request details

      const response = await axios.put(url, data, { headers });
      
      //console.log('Response:', response);  // Log the response

      updateDesignTokens('global', { [tokensId]: localTokens[tokensId] });  // Update with the correct structure
      setGlobalTokens({ [tokensId]: localTokens[tokensId] });  // Set with the correct structure
      console.log('Tokens updated:', response.data);

    } catch (error) {
      console.error('Error updating tokens:', error.response ? error.response.data : error.message);
    }

    onClose();
  };



  useEffect(() => {
      //console.log('Local Tokens updated:', localTokens);  // Log localTokens whenever it's updated
  }, [localTokens]);

  useEffect(() => {
      //console.log('Original Tokens updated:', originalTokens);  // Log originalTokens whenever it's updated
  }, [originalTokens]);

  useEffect(() => {
      //console.log('Global tokens updated:', globalTokens);
      setLocalTokens(globalTokens);
      setOriginalTokens(globalTokens);
  }, [globalTokens]);

  return (
    <Modal
      title="Edit Global Design Tokens"
      visible={isVisible}
      onCancel={handleCancel}
      onOk={handleSave}
      okText="Save"  // Change the button label to "Save"
    >
      {Object.entries(actualTokens || {}).map(([category, tokens]) => (
          <div key={category}>
              <h3>{category}</h3>
              {Object.entries(tokens).map(([key, value]) => (
                  <div key={key}>
                      <label>{key}:</label>
                      {isColor(value) ? (
                          <>
                              <input
                                  type="color"
                                  value={actualTokens[category][key]}
                                  onChange={(e) => handleTokenChange(category, key, e.target.value)}
                              />
                              <input
                                  type="text"
                                  value={actualTokens[category][key]}
                                  onChange={(e) => handleTokenChange(category, key, e.target.value)}
                              />
                          </>
                      ) : (
                          <input
                              type="text"
                              value={actualTokens[category][key]}
                              onChange={(e) => handleTokenChange(category, key, e.target.value)}
                          />
                      )}
                  </div>
              ))}
          </div>
      ))}      
    </Modal>
  );
};

export default GlobalTokenEditor;