// frontend/src/components/properties-sidebar/ColorOptionsHelper.js
import React from 'react';
import { Dropdown, Button, Menu } from 'antd';
import CustomExpandIcon from './CustomExpandIcon';
import { getRelevantTokens } from './utils';

export const renderColorPicker = (
    propertyType, 
    selectedComponentInfo, 
    globalTokens, 
    editedTokens, 
    handleTokenChange, 
    tokenNames, 
    renderDropdownMenu, 
    setEditedTokens
) => {
    if (!selectedComponentInfo || !selectedComponentInfo.name) return null;

    const tokenName = tokenNames[selectedComponentInfo.name]?.[propertyType] || '';
    const inputValue = editedTokens[selectedComponentInfo.name]?.[propertyType] || '';

    return (
        <div className="prop-sidebar-item" style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
            <input
                className="color-picker"
                type="color"
                value={inputValue}
                onChange={(e) => handleTokenChange(selectedComponentInfo.name, propertyType, e.target.value)}
            />
            <input
                type="text"
                value={tokenName || inputValue}
                placeholder={propertyType}
                onChange={(e) => handleTokenChange(selectedComponentInfo.name, propertyType, e.target.value)}
            />
            {renderDropdownMenu(
                propertyType, 
                selectedComponentInfo, 
                editedTokens, 
                handleTokenChange, 
                globalTokens, 
                tokenNames, 
                setEditedTokens
            )}
        </div>
    );
};
