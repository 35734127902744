// frontend/src/components/homepage/HomeSection2/HomeSection2.js
import React from 'react';
import './HomeSection2.css';
import HeadingKeyline from '../../../assets/homepage/headings/HeadingKeyline.svg';
import GitHubIcon from '../../../assets/homepage/HomeSection2/github-icon.svg'; 
import S3Icon from '../../../assets/homepage/HomeSection2/s3-icon.svg';
import SyncedIcon from '../../../assets/homepage/HomeSection2/synced-icon.svg';
import WebbifyIcon from '../../../assets/homepage/HomeSection2/webbify-icon.svg';
import { ReactComponent as Line1 } from '../../../assets/homepage/HomeSection2/line1.svg';
import { ReactComponent as Line2 } from '../../../assets/homepage/HomeSection2/line2.svg';
import { ReactComponent as Line3 } from '../../../assets/homepage/HomeSection2/line3.svg';

const HomeSection2 = () => {
  return (
    <div className="home-section-2">
      <div className="homeHeadingH2">
        <img src={HeadingKeyline} alt="HeadingKeyline" className="HeadingKeyline" />
        <h2>Connect to GitHub</h2>
        <p>Work directly with your existing files in our app.</p>
      </div>
      <div className="steps">
        <div className="icon-row">
          <img src={WebbifyIcon} alt="Webbify" className="icon" />
          <p>Log into and navigate to settings</p>
        </div>
        <div className="icon-row">
          <img src={GitHubIcon} alt="GitHub" className="icon" />
          <p>Connect with GitHub & Clone repo</p>
        </div>
        <div className="icon-row">
          <img src={S3Icon} alt="S3 Bucket" className="icon" />
          <p>Save repository to AWS S3 Bucket</p>
        </div>
        <div className="icon-row">
          <img src={SyncedIcon} alt="Synced Files" className="icon" />
          <p>Sync S3 Bucket with GitHub repo</p>
        </div>
      </div>
    </div>
  );
};

export default HomeSection2;
