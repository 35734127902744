// frontend/src/components/CodeMirrorEditor.js
import React, { useRef, useState, forwardRef, useImperativeHandle } from 'react';
import { Controlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/xml/xml';
import 'codemirror/mode/css/css';
import 'codemirror/mode/markdown/markdown';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/addon/search/search';
import 'codemirror/addon/search/searchcursor';
import 'codemirror/addon/dialog/dialog.css';
import 'codemirror/addon/dialog/dialog';

const CodeMirrorEditor = forwardRef(({ value, onChange, lineWrapping, mode }, ref) => {
  const editorRef = useRef(null);
  const [isSplitView, setIsSplitView] = useState(false);

  useImperativeHandle(ref, () => ({
    handleSearch,
    handleReplace,
    handleCopy,
    handleUndo,
    handleRedo,
    toggleSplitView,
  }));

  const handleChange = (editor, data, value) => {
    onChange(value);
  };

  const handleSearch = () => {
    editorRef.current.getCodeMirror().execCommand('findPersistent');
  };

  const handleReplace = () => {
    editorRef.current.getCodeMirror().execCommand('replace');
  };

  const handleCopy = () => {
    const editor = editorRef.current.getCodeMirror();
    const selection = editor.getSelection();
    if (selection) {
      navigator.clipboard.writeText(selection);
    }
  };

  const handleUndo = () => {
    editorRef.current.getCodeMirror().execCommand('undo');
  };

  const handleRedo = () => {
    editorRef.current.getCodeMirror().execCommand('redo');
  };

  const toggleSplitView = () => {
    setIsSplitView(!isSplitView);
  };

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: isSplitView ? 'row' : 'column' }}>
        <CodeMirror
          ref={editorRef}
          value={value}
          options={{
            mode: mode || 'javascript',
            theme: 'material',
            lineNumbers: true,
            lineWrapping: lineWrapping,
            extraKeys: {
              "Ctrl-F": "findPersistent",
              "Ctrl-H": "replace"
            }
          }}
          onBeforeChange={handleChange}
        />
        {isSplitView && (
          <CodeMirror
            value={value}
            options={{
              mode: mode || 'javascript',
              theme: 'material',
              lineNumbers: true,
              lineWrapping: lineWrapping,
              readOnly: true,
            }}
          />
        )}
      </div>
    </div>
  );
});

export default CodeMirrorEditor;
