// frontend/src/pages/UserDashboard.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Layout, Menu, Input, Button, Select, Modal, Tree } from 'antd';
import Header from '../components/Header';

const { Sider, Content } = Layout;
const { Option } = Select;

const UserDashboard = ({ isLoggedIn, setIsLoggedIn }) => {
  const [selectedKey, setSelectedKey] = useState('1');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [showReset, setShowReset] = useState(false);
  const [isConnectedToGitHub, setIsConnectedToGitHub] = useState(false);
  const [githubUsername, setGitHubUsername] = useState('');
  const [githubToken, setGitHubToken] = useState('');
  const [githubError, setGitHubError] = useState('');
  const [repos, setRepos] = useState([]);
  const [selectedRepo, setSelectedRepo] = useState('');
  const [branch, setBranch] = useState('main');
  const [userId, setUserId] = useState('');

  const [clonedRepos, setClonedRepos] = useState([]);
  const [selectedClonedRepo, setSelectedClonedRepo] = useState('');

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [treeData, setTreeData] = useState([]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem('token');
        console.log('Token retrieved from localStorage:', token);
        if (!token) {
          console.log('No token available.');
          setIsLoggedIn(false);
          return;
        }
        const userResponse = await axios.get('/auth/user', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        console.log('User data fetched:', userResponse.data);
        setEmail(userResponse.data.email);
        setPassword(userResponse.data.password);
        setUserId(userResponse.data._id);

        // Fetch GitHub details
        const githubResponse = await axios.get('/github/details', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        console.log('GitHub details fetched:', githubResponse.data);
        setGitHubUsername(githubResponse.data.username);
        setGitHubToken(githubResponse.data.githubAccessToken);
        setIsConnectedToGitHub(true);
        setIsLoggedIn(true);
      } catch (error) {
        if (error.response?.status === 401) {
          console.log('Unauthorized access - clearing token');
          localStorage.removeItem('token');
          setIsLoggedIn(false);
        } else if (error.response?.status === 404) {
          console.log('No GitHub details found for this user.');
          setIsConnectedToGitHub(false);
        } else {
          console.error('Error fetching user data:', error);
        }
      }
    };

    fetchUserData();
  }, [setIsLoggedIn]);

  // fetch repos
  useEffect(() => {
    const fetchRepos = async () => {
      if (isConnectedToGitHub) {
        try {
          const token = localStorage.getItem('token');
          const response = await axios.get('/github/repos', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setRepos(response.data);
        } catch (error) {
          console.error('Error fetching repositories:', error);
        }
      }
    };
    fetchRepos();
  }, [isConnectedToGitHub]);

  // Fetch cloned repos from S3
  useEffect(() => {
    const fetchClonedRepos = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('/github/cloned-repos', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setClonedRepos(response.data);
      } catch (error) {
        console.error('Error fetching cloned repositories:', error);
      }
    };
    fetchClonedRepos();
  }, []);

  const handleGitHubConnect = async () => {
    if (!githubUsername || !githubToken) {
      alert('Please enter your GitHub username and personal access token.');
      return;
    }

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        '/github/connect',
        {
          username: githubUsername,
          accessToken: githubToken,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log('GitHub connection response:', response.data);
      setIsConnectedToGitHub(true);
      setGitHubError('');
    } catch (error) {
      console.error('Error connecting to GitHub:', error);
      setGitHubError('Failed to connect to GitHub. Please check your credentials.');
    }
  };

  const handleCloneRepo = async () => {
    if (!selectedRepo) {
      alert('Please select a repository to clone.');
      return;
    }

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        '/github/clone',
        {
          repoName: selectedRepo,
          branch,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      alert('Repository cloned successfully.');
      fetchClonedRepos();
    } catch (error) {
      console.error('Error cloning repository:', error);
      alert('Failed to clone repository.');
    }
  };

  const handleSaveGitHubDetails = async () => {
    if (!githubUsername || !githubToken) {
      alert('Please enter your GitHub username and personal access token.');
      return;
    }

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        '/github/save',
        {
          username: githubUsername,
          accessToken: githubToken,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log('GitHub details saved response:', response.data);
      alert('GitHub details saved successfully.');
    } catch (error) {
      console.error('Error saving GitHub details:', error);
      alert('Failed to save GitHub details. Please try again.');
    }
  };

  const handleSave = async () => {
    try {
      if (newPassword === repeatPassword) {
        const updateData = { email };

        if (newPassword) {
          updateData.password = newPassword;
        }

        // Include the token in the headers (retrieve it again from local storage)
        const updatedToken = localStorage.getItem('token') || '';
        const response = await axios.put(
          '/auth/me',
          updateData,
          {
            headers: {
              Authorization: `Bearer ${updatedToken}`,
            },
          }
        );

        console.log('Update Response:', response.data);

        // Update the 'password' state with the new password
        setPassword(newPassword);

        alert('Changes saved successfully!');
      } else {
        alert('New passwords do not match!');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCancel = () => {
    // Reset the form fields
    setEmail('');
    setPassword('');
    setNewPassword('');
    setRepeatPassword('');
    setShowReset(false);
  };

  //const handleOpenModal = async () => {
  //  setIsModalVisible(true);
  //  await fetchRepoFiles();
  //};

  const fetchRepoFiles = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`/github/${selectedClonedRepo}/files`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
      });
      console.log('Raw Tree Data from S3:', response.data);
      if (Array.isArray(response.data)) {
        setTreeData(response.data);
      } else {
        console.error('Unexpected data format:', response.data);
      }
    } catch (error) {
      console.error('Error fetching repo files from S3:', error);
    }
  };

  const handleOpenModal = async () => {
    if (!selectedClonedRepo) {
      alert('Please select a cloned repository to view.');
      return;
    }
    setIsModalVisible(true);
    await fetchRepoFiles();
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const contentMap = {
    '1': (
      <div>
        <h2>Account Info</h2>
        <label>Email: </label>
        <Input value={email} onChange={(e) => setEmail(e.target.value)} />
        <label>Password: </label>
        <Input.Password value={password} onChange={(e) => setPassword(e.target.value)} />
        <a onClick={() => setShowReset(true)}>Reset Password</a>
        {showReset && (
          <>
            <Input.Password placeholder="New Password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
            <Input.Password placeholder="Repeat New Password" value={repeatPassword} onChange={(e) => setRepeatPassword(e.target.value)} />
          </>
        )}
        <Button onClick={handleSave}>Save</Button>
        <Button onClick={handleCancel}>Cancel</Button>
      </div>
    ),
    '2': (
      <div>
        <h2>GitHub Integration</h2>
        <p>To integrate your GitHub account, add your GitHub Username and your GitHub Personal Access Token (can be generated in: Settings > Developer Settings > Personal Access Tokens > Tokens Classic > Generate new Token).</p>
        <Input
          placeholder="GitHub Username"
          value={githubUsername}
          onChange={(e) => setGitHubUsername(e.target.value)}
        />
        <Input.Password
          placeholder="GitHub Personal Access Token"
          value={githubToken}
          onChange={(e) => setGitHubToken(e.target.value)}
        />
        <Button onClick={handleGitHubConnect}>Connect to GitHub</Button>
        <Button onClick={handleSaveGitHubDetails} style={{ marginLeft: '10px' }}>Save GitHub Details</Button>
        {isConnectedToGitHub && (
          <div>
            <Select
              style={{ width: '100%', marginTop: '20px' }}
              placeholder="Select a repository to clone"
              onChange={(value, option) => setSelectedRepo(option.key)} // This ensures the full name (username/repoName) is used.
            >
              {repos.map(repo => (
                <Option key={repo.full_name} value={repo.full_name}>
                  {repo.name}
                </Option>
              ))}
            </Select>
            <Input
              placeholder="Branch (default: main)"
              value={branch}
              onChange={(e) => setBranch(e.target.value)}
              style={{ marginTop: '10px' }}
            />
            <Button onClick={handleCloneRepo} style={{ marginTop: '10px' }}>
              Clone Repo
            </Button>
            <Select
              style={{ width: '100%', marginTop: '20px' }}
              placeholder="Select a cloned repository to view"
              onChange={(value) => setSelectedClonedRepo(value)} // Sets the selected cloned repo
            >
              {clonedRepos.map(repo => (
                <Option key={repo} value={repo}>
                  {repo}
                </Option>
              ))}
            </Select>
            <Button onClick={handleOpenModal} style={{ marginTop: '10px' }}>
              View Cloned Files
            </Button>
          </div>
        )}
        {githubError && <p style={{ color: 'red' }}>{githubError}</p>}
      </div>
    ),
    '3': <div>Sidebar item 3</div>,
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider width={200}>
        <Menu 
          mode="inline" 
          defaultSelectedKeys={['1']} 
          style={{ height: '100%', borderRight: 0 }}
          onSelect={({ key }) => setSelectedKey(key)}
        >
          <Menu.Item key="1">Account settings</Menu.Item>
          <Menu.Item key="2">GitHub integration</Menu.Item>
          <Menu.Item key="3">Sidebar item 3</Menu.Item>
        </Menu>
      </Sider>
      <Layout>
        <Header isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
        <Content style={{ padding: '0 50px' }}>
          <div className="user-dashboard-content-wrap">
            <h2>User Dashboard</h2>
            {contentMap[selectedKey]}
          </div>
        </Content>
      </Layout>
      <Modal
        title="Cloned Repository Files"
        visible={isModalVisible}
        onCancel={handleModalClose}
        footer={null}
      >
        <Tree
          treeData={treeData}
          defaultExpandAll
        />
      </Modal>
    </Layout>
  );
};

export default UserDashboard;
