// frontend/src/components/homepage/HomeSection6/HomeSection6.js
import React from 'react';
import './HomeSection6.css';
import HeadingKeyline from '../../../assets/homepage/headings/HeadingKeyline.svg';
import ImageLeft from '../../../assets/homepage/HomeSection6/jira-panel.png';
import ImageRight from '../../../assets/homepage/HomeSection6/jira-ticket.png';

const HomeSection6 = () => {
  return (
    <div className="home-section-6">
      <div className="homeHeadingH2">
        <img src={HeadingKeyline} alt="Heading Keyline" className="HeadingKeyline" />
        <h2>Work smarter</h2>
        <p>Edit and track Jira tickets without leaving the app.</p>
      </div>

      <div className="content-wrapper">
        <div className="left-column">
          <img src={ImageLeft} alt="Jira Backlog View" />
        </div>
        <div className="right-column">
          <img src={ImageRight} alt="Jira Ticket Detail View" />
          <p>
            Access and manage your tagged Jira tickets directly from your project board within the app, eliminating distractions and boosting productivity.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HomeSection6;
