// frontend/src/components/homepage/HomeSection4/HomeSection4.js
import React from 'react';
import './HomeSection4.css';
import HeadingKeyline from '../../../assets/homepage/headings/HeadingKeyline.svg';
import Image1 from '../../../assets/homepage/HomeSection4/visualeditor.png';
import Image2 from '../../../assets/homepage/HomeSection4/codeeditor.png';

const HomeSection4 = () => {
  return (
    <div className="home-section-4">
      <div className="homeHeadingH2">
        <img src={HeadingKeyline} alt="HeadingKeyline" className="HeadingKeyline" />
        <h2>Unleash your creativity</h2>
        <p>Combine visual and code editors to build websites your way.</p>
      </div>
      <div className="content-container">
        <div className="images-container">
          <img src={Image1} alt="Image 1" className="image image1" />
          <div className="image2withText">
            <img src={Image2} alt="Image 2" className="image image2" />
            <div className="text-container">
              <p>
                Effortlessly switch between code view and visual editor to preview and make changes instantly!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeSection4;
