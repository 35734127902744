// fontend/src/comopnents/ComponentSidebar.js
import React, { useState, useEffect, useRef } from 'react';
import { fetchUserComponents } from './UserComponentImports';
import { components } from './ComponentImports';
import ComponentWindow from './ComponentWindow';
import { CaretRightOutlined } from '@ant-design/icons';

const ComponentSidebar = ({ onDragStart, token, designTokens }) => {
  const [userComponents, setUserComponents] = useState([]);
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [imageUrls, setImageUrls] = useState({});
  const [draggedComponent, setDraggedComponent] = useState(null);
  const dragImageRef = useRef(null);
  const hiddenDragImageRef = useRef(null);

  useEffect(() => {
    async function loadUserComponents() {
      const components = await fetchUserComponents(token);
      setUserComponents(components);
    }

    loadUserComponents();
  }, [token]);

  useEffect(() => {
    const fetchImages = async () => {
      const urls = {};
      for (const componentName of Object.keys(components)) {
        try {
          const response = await fetch(`/api/get-screenshot-url?componentName=${componentName}`);
          const { url } = await response.json();
          urls[componentName] = url;
        } catch (error) {
          console.error(`Error fetching image for ${componentName}:`, error);
        }
      }
      console.log('Fetched image URLs:', urls);
      setImageUrls(urls);
    };

    fetchImages();
  }, []);

  const handleDragStart = (event, componentName) => {
    setDraggedComponent(componentName);
    event.dataTransfer.setData('componentName', componentName);
    event.dataTransfer.setData('designTokens', JSON.stringify(designTokens[componentName]));

    if (hiddenDragImageRef.current) {
      const img = new Image();
      img.src = imageUrls[componentName];
      img.onload = () => {
        const hiddenDiv = hiddenDragImageRef.current;
        hiddenDiv.innerHTML = ''; // Clear the hidden div
        hiddenDiv.appendChild(img); // Append the image to the hidden div

        // Set the drag image
        event.dataTransfer.setDragImage(hiddenDiv, img.width / 2, img.height / 2);
      };
    }
  };

  const handleDragEnd = () => {
    setDraggedComponent(null);
  };

  const handleOpenWindow = (componentName) => {
    console.log(`Opening component in new window: ${componentName}`);
    setSelectedComponent({ componentName, designTokens: designTokens[componentName] });
  };

  return (
    <div className="left-sidebar">
      <h4>Components</h4>
      <ul>
        {Object.keys(components).map((componentName) => (
          <li
            key={componentName}
            draggable
            onDragStart={(e) => handleDragStart(e, componentName)}
            onDragEnd={handleDragEnd}
            className={draggedComponent === componentName ? 'dragging' : ''}
          >
            <div className="component-left-content">
              <div className="componentThumbnail">
                {imageUrls[componentName] ? (
                  <img src={imageUrls[componentName]} alt={componentName} />
                ) : (
                  <span classname="mini-loading-label">Loading...</span>
                )}
              </div>
              <p>{componentName}</p>
            </div>
            <button onClick={() => handleOpenWindow(componentName)}><CaretRightOutlined /></button>
          </li>
        ))}
      </ul>
      <h4>User Components</h4>
      <ul>
        {userComponents.map(component => (
          <li
            key={component.name}
            draggable
            onDragStart={(e) => onDragStart(e, component.name)}
            // Implement onDragEnd and className handling as needed
          >
            <div className="component-left-content">
              {/* Placeholder or actual image */}
              <p>{component.name}</p>
            </div>
            <button onClick={() => {/* open component */}}><CaretRightOutlined /></button>
          </li>
        ))}
      </ul>
      {selectedComponent && (
        <ComponentWindow
          componentName={selectedComponent.componentName}
          designTokens={selectedComponent.designTokens}
        />
      )}
      <div ref={hiddenDragImageRef} style={{ position: 'absolute', top: '-1000px', left: '-1000px', visibility: 'hidden' }} />
    </div>
  );
};

export default ComponentSidebar;