// frontend/src/components/PageContainer.js 
import React, { useContext, useState, useEffect, Suspense } from 'react';
import { components as componentImports } from './ComponentImports';
import { getComponentProps } from '../hooks/getComponentProps';
import { PageContentContext } from '../contexts/PageContentContext';
import { NestedComponentsContext } from '../contexts/NestedComponentsContext';
import { ComponentPropsContext } from '../contexts/ComponentPropsContext';
import { usePageContentUtils } from '../hooks/usePageContentUtils';
import { reconstructFrontendStructureFromBackendSchema } from '../utils/reconstructFrontendStructureFromBackendSchema';
import DivContainer from './ui_components/DivContainer/DivContainer'; // Adjust the path as needed

import useComponentDrop from '../hooks/useComponentDrop';
import {
  addRow,
  removeRow,
  removeColumn,
  addComponentToColumn,
  handleColumnChange,
  handleColumnDivChange
} from '../utils/rowColumnManager';

const PageContainer = ({ 
  components, 
  designTokens, 
  setSelectedComponentInfo, 
  editedTokens, 
  fetchDesignTokens, 
  updateDesignTokens,
}) => {
  const [renderedRows, setRenderedRows] = useState([]);
  const { rows, setRows, pageContent, setPageContent } = useContext(PageContentContext);
  const { nestedComponents } = useContext(NestedComponentsContext);
  const { selectedComponentProps } = useContext(ComponentPropsContext);
  const { reconstructFrontendStructureFromBackendSchema } = usePageContentUtils(components);
  const handleComponentDrop = useComponentDrop(components, designTokens);

  // Utils implementing rowColumnManager functions
  const onAddRow = () => addRow(rows, setRows);
  const onRemoveRow = (index) => removeRow(rows, setRows, index);
  const onRemoveColumn = (rowIndex, colIndex) => removeColumn(rows, setRows, rowIndex, colIndex);
  const onAddComponentToColumn = (rowIndex, colIndex, newComponent) => addComponentToColumn(rows, setRows, rowIndex, colIndex, newComponent);
  const onHandleColumnChange = (rowIndex, newColumnCount) => handleColumnChange(rows, setRows, rowIndex, newColumnCount);
  const onHandleColumnDivChange = (rowIndex, colIndex, newColumnCount) => handleColumnDivChange(rows, setRows, rowIndex, colIndex, newColumnCount);

  const handleComponentDragStart = (event, rowIndex, colIndex) => {
    event.dataTransfer.setData('sourceRow', rowIndex.toString());
    event.dataTransfer.setData('sourceCol', colIndex.toString());
  };

  const allowDrop = (event) => {
    event.preventDefault();
  };

  // utils for handleComponentDrop function
  const onComponentDrop = (event, rowIndex, colIndex) => {
    handleComponentDrop(componentImports, rows, setRows, event, rowIndex, colIndex);
    savePageContentToContext();
  };

  // Render components and handle on click behaviour
  const renderedComponents = (componentsArray, rowIndex, colIndex) => {
    console.log(`Rendering components for row ${rowIndex}, col ${colIndex}:`, componentsArray);
    if (!Array.isArray(componentsArray)) {
      console.error("Invalid componentsArray:", componentsArray);
      return null;
    }

    return componentsArray.map((componentInfo, index) => {
      console.log(`Rendering component ${componentInfo.name} with ID ${componentInfo.id}`);
      if (!componentInfo || !componentInfo.component) {
        console.warn('Invalid or unloaded component:', componentInfo);
        return null;
      }

      const Component = componentInfo.component;
      const tokens = editedTokens[componentInfo.id] || designTokens[componentInfo.id] || componentInfo.tokens;
      const componentProps = selectedComponentProps[componentInfo.id] || componentInfo.props;

      if (componentInfo.name === 'DivContainer') {
        console.log(`DivContainer ID: ${componentInfo.id}`);
        const nestedComponentsArray = Array.isArray(componentInfo.nestedComponents) ? componentInfo.nestedComponents : [];
        // Pass nestedComponents properly to the DivContainer
        return (
          <DivContainer 
              key={index}
              id={componentInfo.id}
              {...componentProps}
              designTokens={tokens} 
              editedTokens={tokens}
              nestedComponents={componentInfo.nestedComponents || {}}
              draggable="true"
              onDragStart={(e) => handleComponentDragStart(e, rowIndex, colIndex)}
              data-component-name={componentInfo.name}
              onClick={() => handleComponentClick(componentInfo)}
          />
        );
      }

      return (
        <div 
          key={index}
          draggable="true"
          className="rendered-component" 
          onDragStart={(e) => handleComponentDragStart(e, rowIndex, colIndex)}
          data-component-name={componentInfo.name}
          onClick={() => handleComponentClick(componentInfo)}
        >
          <Component key={componentInfo.id} {...componentProps} designTokens={tokens} editedTokens={tokens} onClick={() => handleComponentClick(componentInfo)} />
        </div>
      );
    });
  };

  const handleComponentClick = (component) => {
      //console.log('handleComponentClick called with component:', component.name); 
      const tokens = editedTokens[component.name] || component.tokens;
      const componentName = component.name;
      const componentProps = getComponentProps(component.component);
      setSelectedComponentInfo({ 
          id: component.id,
          name: component.name,
          props: componentProps,
      });
  };

  // useEffect hook for updating renderedRows when rows are updated
  useEffect(() => {
    console.log('PageContainer - useEffect: Rows before rendering', rows);
    const newRenderedRows = rows.map(row => ({
      ...row,
      components: row.components.map(columnComponents => {
        return Array.isArray(columnComponents) ? renderedComponents(columnComponents) : [];
      }),
    }));
    console.log('PageContainer - Rendered rows:', newRenderedRows);
    setRenderedRows(newRenderedRows);
  }, [rows, designTokens, editedTokens, nestedComponents]);

  const savePageContentToContext = () => {
    const rowsArray = document.querySelectorAll('.page-container .row');
    const pageContentStructure = Array.from(rowsArray).map(row => {
      const columnsArray = row.querySelectorAll('.column');
      return {
        columns: Array.from(columnsArray).map(column => {
          const componentsArray = column.querySelectorAll('.rendered-component');
          return {
            columnSize: parseInt(column.getAttribute('data-column-size'), 10),
            components: Array.from(componentsArray).map(component => {
              const componentName = component.getAttribute('data-component-name');
              const componentId = component.getAttribute('id');
              const componentProps = selectedComponentProps[componentName] || {};  
              return {
                id: componentId,
                name: componentName,
                props: componentProps,
                tokens: editedTokens[componentId] || designTokens[componentId] || {},
                nestedComponents: nestedComponents[componentId] || []
                // Include other necessary properties like 'tokens' if required
              };
            }),
          };
        }),
      };
    });
    console.log('PageContainer - Saving page content:', pageContentStructure);
    setPageContent(pageContentStructure); // Update the React context
  };

  const getColumnSize = (column) => {
      const columnSize = column.getAttribute('data-column-size');
      return columnSize ? parseInt(columnSize, 10) : null;
  };

  return (
    <div className="page-container">
      {rows.map((row, rowIndex) => (
        <div key={rowIndex} className="container">
          <div className="bar-container">
            <div className="column-options">
              <button onClick={() => onHandleColumnChange(rowIndex, row.columns.length + 1)}>
                +
              </button>
              {row.columns.map((colCount, colIndex) => (
                <div key={colIndex}>
                  <select
                    value={colCount}
                    onChange={(e) =>
                      onHandleColumnDivChange(rowIndex, colIndex, parseInt(e.target.value))
                    }
                  >
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((count) => (
                      <option key={count} value={count}>
                        {count} Col{count > 1 ? 's' : ''}
                      </option>
                    ))}
                  </select>

                  <button onClick={() => onRemoveColumn(rowIndex, colIndex)}>–</button>
                </div>
              ))}
            </div>
            <button onClick={() => onRemoveRow(rowIndex)}>Remove Row</button>
          </div>
          <div className="row">
            {row.columns.map((colCount, colIndex) => (
              <div
                key={colIndex}
                className="column"
                style={{ flex: `1 ${(100 / row.columns.reduce((acc, curr) => acc + curr, 0)) * colCount}%` }}
                data-column-size={colCount}
                onDrop={(event) => handleComponentDrop(event, rowIndex, colIndex)}
                onDragOver={allowDrop}
              >
                {row.components[colIndex] && row.components[colIndex].map((component, componentIndex) => (
                  <React.Fragment key={componentIndex}>
                    {renderedComponents([component], rowIndex, colIndex)}
                  </React.Fragment>
                ))}
              </div>
            ))}
          </div>
        </div>
      ))}
      <div className="add-outter-row">
        <button className="add-row" onClick={onAddRow}>Add Row</button>
      </div>
    </div>
  );
};

export default PageContainer;