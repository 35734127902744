// frontend/src/components/media/MediaSidebar.js
import React, { useEffect, useState } from 'react';
import { Collapse, List } from 'antd';
import axios from 'axios';
import './MediaSidebar.css';

const { Panel } = Collapse;

const MediaSidebar = ({ onFolderSelect }) => {
  const [folders, setFolders] = useState([]);

  useEffect(() => {
    const fetchFolders = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('/github/cloned-repos', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const structuredFolders = buildFolderTree(response.data);
        setFolders(structuredFolders);
      } catch (error) {
        console.error('Error fetching folders:', error);
      }
    };

    fetchFolders();
  }, []);

  const buildFolderTree = (folderPaths) => {
    const tree = [];

    folderPaths.forEach((path) => {
      const parts = path.split('/').filter((part) => part); // Split and filter out empty parts
      let currentLevel = tree;

      parts.forEach((part, index) => {
        let existingPart = currentLevel.find((item) => item.title === part);

        if (!existingPart) {
          existingPart = {
            title: part,
            key: parts.slice(0, index + 1).join('/'),
            children: [],
          };
          currentLevel.push(existingPart);
        }

        currentLevel = existingPart.children;
      });
    });

    return tree;
  };

  const renderFolders = (folders) => {
    return folders.map((folder) => {
      if (folder.children && folder.children.length > 0) {
        return (
          <Panel header={folder.title} key={folder.key}>
            {renderFolders(folder.children)}
          </Panel>
        );
      } else {
        return (
          <List.Item
            key={folder.key}
            onClick={() => onFolderSelect(folder.key)}
          >
            {folder.title}
          </List.Item>
        );
      }
    });
  };

  return (
    <div className="mediaSidebar">
      <Collapse accordion>
        {renderFolders(folders)}
      </Collapse>
    </div>
  );
};

export default MediaSidebar;
